import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { useHistory } from "react-router-dom";
import { MockDocTableColumn } from './Columns'; 
import { BsLightningFill } from "react-icons/bs";
// import GlobleFilter from './../tableFilter/GlobleFilter';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, } from "react-icons/fa";
// import { CheckBox } from './../tableFilter/CheckBox';
import { CheckBox ,GlobleFilter } from './../tableFilter';
import { Dropdown, Modal } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
import axios from 'axios';
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import { BuyDiamond } from '../Popup/BuyDiamond';
import HoldSkeleton from './HoldSkeleton'
import ConfirmGoodsSkeleton from './../confirmgoods/ConfirmGoodsSkeleton'
import PerfectScrollbar from "react-perfect-scrollbar";
import { IoMdClose } from 'react-icons/io';
import Cookies from "js-cookie"

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
// import  ExpandedDetails from "./ExpandedDetails"
export const HoldDiamonds = (props) => {
    // console.log("EVERY TIME")
    const history = useHistory();
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    // const renderRowSubComponent = React.useCallback(
    //     () => (
    //         <>
    //             <ExpandedDetails />
    //         </>
    //     ),[]
    //   )
        
    const columns = useMemo(()=> MockDocTableColumn(props.conversionrate,props.symbol,history,props.language,props.userinfo),[] )
    // const data = useMemo(()=> StockAPI,[])
    const [data,setData] = useState([])
    const [loader,setLoader] = useState(true)
    useEffect(() => {
        axios({
            method: "get",
            url: `${NODE_API_URL}/IVP/hold-diamond-list`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            }
        }).then((res) => {
            setLoader(false)
            if (res && res.data) {
                // setDashData(res.data);
                // setSalesData(res.data.salesExecutiveDetail);
                // // console.log("data", res);
                // console.log(res.data,"FETCHING")
                setData(res.data.data)
                // setData(res.data)
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    },[])

    const globalFilterFunction = (rows, columnIds, filterValue) => {
        return rows.filter(row => {
            const values = Object.values(row.original); // Get all field values of the row
            return values.some(value => 
                String(value).toLowerCase().includes(filterValue.toLowerCase())
            );
        });
    };
   
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        // visibleColumns, 
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        toggleAllRowsSelected,
        state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data,
        globalFilter: globalFilterFunction
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                // console.log(columns,"Columns")
                return[
                    {
                        id:'selection',
                        Cell: ({ row }) => (
                            <div>
                              {row.original.confirm_status !== "Release" && row.original.confirm_status !== "Rejected"?<CheckBox title="Select" {...row.getToggleRowSelectedProps()} />:""}
                              {/* {row.original.diamondStatus !== "Release"?
                              <Checkbox
                              checked={row.isItemSelected}
                              className="py-0"
                              inputProps={{ 'aria-labelledby': row.labelId }}
                              onClick={(event) => handleClick(event, row.original.id)}
                          />
                              :""} */}

                            </div>
                          ),
                    },
                    ...columns,
                ]
            })
        }
    )
    // const [selected, setSelected] = React.useState([]);
    // const isSelected = (name) => selected.indexOf(name) !== -1;
    // const handleClick = (event, name) => {
    //     // console.log(selected,"handleClick Beforee")
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected = [];
    
    //     if (selectedIndex === -1) {
    //       newSelected = newSelected.concat(selected, name);
    //     } else if (selectedIndex === 0) {
    //       newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //       newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //       newSelected = newSelected.concat(
    //         selected.slice(0, selectedIndex),
    //         selected.slice(selectedIndex + 1),
    //       );
    //     }
    
    //     setSelected(newSelected);
    //   };
      const BuyDiamondPopUp = () => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Atleast One Diamond!")
            return
        }
        // console.log("BuyDiamond")
        setBuyPopUp(true)
    // console.log(selectedFlatRows,"selectedFlatRows")
  
    let temptotalcarat = 0
    let temptotalinrcar = 0
    let temptotalourprice = 0
    let diamondcount = 0
        let mappedvalues = selectedFlatRows.map(value => {
            diamondcount += 1
            temptotalcarat += parseFloat(value.original.carat)
            temptotalourprice += parseFloat(value.original["ourPrice"])
            temptotalinrcar += (diamondcount>0?parseFloat(value.original["ourRate"])/diamondcount:0)
            return value.original
        })
        // console.log(temptotalcarat,"temptotalcarat")
        setDiamondSelected(mappedvalues)
        setDiamondcount(diamondcount)
        setBuyPopUpInr(temptotalourprice/temptotalcarat)
        setBuyPopUpTotal(temptotalourprice)
      }
      const [diamondcount,setDiamondcount] = useState(0)
      const [totalcarat,setTotalCarat] = useState(0)
      const [totalinrcar,setTotalInrCar] = useState(0)
      const [totalourprice,setTotalOurPrice] = useState(0)
      useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selectedFlatRows.map(value => {
            temptotalcarat += parseFloat(value.original.carat)
            temptotalourprice += parseFloat(value.original["ourPrice"])
            temptotalinrcar += (selectedFlatRows.length>0?parseFloat(value.original["ourRate"])/selectedFlatRows.length:0)
            // return value.original
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selectedFlatRows.length > 0?temptotalourprice/temptotalcarat * props.conversionrate:0)
        setTotalOurPrice(temptotalourprice * props.conversionrate)
    },[selectedFlatRows])
      const [diamondselected,setDiamondSelected] = useState([])
      const [buypopup,setBuyPopUp] = useState(false)
    //   const [holdpopup,setHoldPopUp] = useState(false)
      const [buypopupcinr,setBuyPopUpInr] = useState(0)
      const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    //   console.log(diamondselected,buypopupcinr,"diamondselecteddiamondselected")
    const deselectAll = () => {
        toggleAllRowsSelected(false);
      };

    function numberWithCommas(x) {
        if (x) {
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return "0.00";
        }
    }
    return (
        <>
        <div>
            {!loader ?
            <>
                {/* <div className='card card-custom card-sticky gutter-b' >
                    <div className='card-body p-3'>
                        <Alert className="mb-0" variant="danger">
                            {props.language.HOLD_DIAMOND_EXCHANGE}
                        </Alert>
                    </div >
                </div > */}
                <div className="card card-custom card-sticky">
                {selectedFlatRows.length>0?
                        <div className="card-header selecetedHeader custom-scrollbar text-nowrap flex-nowrap pl-10 mb-3" style={{minHeight:"50px"}}>
                            <div className="d-flex natTab justify-content-center align-items-center">
                                <button type="button" className="btn px-8 btn-primary text-nowrap" onClick={() => BuyDiamondPopUp()}><BsLightningFill /> {props.language.SEARCHLIST_BUYDIAMONDBTN}</button>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                                <span className="selected-diamond-box mx-2">
                                    <span className='cursor-pointer' onClick={deselectAll}><IoMdClose className='text-dark'/></span> {selectedFlatRows.length} {props.language.DIAMOND_COMPARISION_SELDIAMONDS} | {props.language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {props.symbol}{numberWithCommas(parseFloat(totalourprice))}
                                </span>
                                <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                <div className="search_result_dropdown">
                                    <Dropdown >
                                        <Dropdown.Toggle size="sm" variant="clear">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Modal
                                        size="sm"
                                        show={CustomizeTable}
                                        onHide={() => setCustomizeTable(false)}
                                        aria-labelledby="Customize-table"
                                    >
                                        <Modal.Header className="px-5 py-3" closeButton>
                                            <Modal.Title id="Customize-table">
                                                Customize Columns
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-2 confirmResult">
                                            <PerfectScrollbar
                                                options={perfectScrollbarOptions}
                                                className="scroll"
                                                style={{ maxHeight: "360px", position: "relative" }}
                                            >
                                                <div>
                                                    <div>
                                                        <label className="checkbox my-2 border p-2">
                                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                            <span className="mr-2"></span>
                                                            Show All
                                                        </label>
                                                    </div> 
                                                    {
                                                        allColumns.map(column =>(
                                                            <div key={column.id} className={`${column.id}`}>
                                                                <label className="checkbox my-2 border p-2">
                                                                    <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                    <span className="mr-2"></span>
                                                                    {column.Header}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </PerfectScrollbar>
                                        </Modal.Body>
                                        <Modal.Footer className="px-5 py-3">
                                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                            <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="card-header custom-scrollbar text-nowrap flex-nowrap pl-10 mb-3" style={{minHeight:"50px"}}>
                            <div className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">{data.length}  {props.language.HOLD_DIAMOND_TOTALDIAMONDS} </span>
                            </div>
                            <div className="card-toolbar flex-md-nowrap">
                                <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                <div className="search_result_dropdown">
                                    <Dropdown >
                                        <Dropdown.Toggle size="sm" variant="clear">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Modal
                                        size="sm"
                                        show={CustomizeTable}
                                        onHide={() => setCustomizeTable(false)}
                                        aria-labelledby="Customize-table"
                                    >
                                        <Modal.Header className="px-5 py-3" closeButton>
                                            <Modal.Title id="Customize-table">
                                                Customize Columns
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-2 confirmResult">
                                            <PerfectScrollbar
                                                options={perfectScrollbarOptions}
                                                className="scroll"
                                                style={{ maxHeight: "360px", position: "relative" }}
                                            >
                                                <div>
                                                    <div>
                                                        <label className="checkbox my-2 border p-2">
                                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                            <span className="mr-2"></span>
                                                            Show All
                                                        </label>
                                                    </div> 
                                                    {
                                                        allColumns.map(column =>(
                                                            <div key={column.id} className={`${column.id}`}>
                                                                <label className="checkbox my-2 border p-2">
                                                                    <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                    <span className="mr-2"></span>
                                                                    {column.Header}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </PerfectScrollbar>
                                        </Modal.Body>
                                        <Modal.Footer className="px-5 py-3">
                                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                            <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="card-body bg-white rounded pt-7 pb-0">
                        <div className="table-responsive w-100">
                            <table className="table table-hover" {...getTableProps()}>
                                <thead className="custom_datatable_head" >
                                    {headerGroups.map(headerGroup =>(
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            { headerGroup.headers.map((column)=>(
                                                <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render('Header')}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {(data.length > 0 && page.length > 0) && page.map((row) => {
                                        prepareRow(row)
                                        const isSelected = selectedRowIds[row.id];

                                        return(
                                            <>
                                                <tr {...row.getRowProps()} key={row.id} className={`${isSelected ? 'bg-success-o-30' : ''}`}>
                                                    {row.cells.map((cell,i) => {
                                                        return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                                {/* {row.isExpanded ? (
                                                    <tr>
                                                        <td className="p-0" colSpan={visibleColumns.length}>
                                                            {renderRowSubComponent({ row })} 
                                                        </td>
                                                    </tr>
                                                ) : null} */}
                                            </>
                                        )
                                    }) || <tr><td className="text-center bg-gray-200" colSpan="100%">No records found</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer border-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                            <div>
                                <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                                <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                                <span className="font-weight-bold text-muted">{'  '}
                                    <input
                                        className="form-control d-inline-block form-control-sm"
                                        type="number"
                                        value={pageIndex + 1}
                                        // defaultValue={pageIndex + 1}
                                        onChange={ e => { 
                                            const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                        style={{maxWidth:'70px'}}
                                    />
                                    {'  '}of{'  '} {pageOptions.length}{'  '}
                                </span>
                                <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                                <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                            </div>
                            <span className="d-flex align-items-center">
                            <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                                <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                    {
                                        [10, 25, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>{pageSize}</option>
                                        ))
                                    }
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
            </> :<ConfirmGoodsSkeleton />}
        </div>
        <BuyDiamond show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} diamondcount={diamondcount} totalourprice={buypopupctotal} language={props.language}/>  

        </>
    )
}