import React, { useState, useEffect,useRef } from "react";
import { Slider, Select, MenuItem,makeStyles } from "@material-ui/core";
import { Field } from "formik";
import axios from "axios";
import Cookies from "js-cookie"
import { NODE_API_URL,BASE_URL} from "./../../../../../env_config";

const useStyles = makeStyles(theme => ({
    menuPaper: {
      maxHeight: '150px'
    }
  }));
const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };

export const SieveSize = ({ language, formik,rangeErr,setRangeErr }) => {
    const classes = useStyles();
    const [sieveSizeData, setSieveSizeData] =useState([])
    const [lastIndex, setLastIndex] =useState(63)
    const [sieveBox, setSieveBox] = useState(false);
    const [sizeBox, setSizeBox] = useState(true);
    const [minSieve, setMinSieve] = useState(1);
    const [maxSieve, setMaxSieve] = useState(lastIndex);
    const [minSize, setMinSize] = useState(1);
    const [maxSize, setMaxSize] = useState(lastIndex);
    const [minSieveText, setMinSieveText] = useState("00");
    const [maxSieveText, setMaxSieveText] = useState("00");
    const [minSizeText, setMinSizeText] = useState("00");
    const [maxSizeText, setMaxSizeText] = useState("00");
    const [minWeight, setMinWeight] = useState("00");
    const [maxWeight, setMaxWeight] = useState("00");
    const minSiveRange = 1;
    const maxSiveRange = lastIndex;
    const minSizeRange = 1;
    const maxSizeRange = lastIndex;
    const [sieveRangeValue, setSieveRangeValue] = useState([formik.values.range_value_from ? formik.values.range_value_from : 1, formik.values.range_value_to ? formik.values.range_value_to :  lastIndex]);
    const [sizeRangeValue, setSizeRangeValue] = useState([1, lastIndex]);
    const [selectedSieveItemIndex, setSelectedSieveItemIndex] = useState(null);
    const [selectedSizeItemIndex, setSelectedSizeItemIndex] = useState(null);
    const menuRefSieve = useRef(null);
    const menuRefSize = useRef(null);
    useEffect(() => {
        if(!(formik.values && formik.values.range_value_from && formik.values.range_value_to)){
            setSieveRangeValue([1,lastIndex])
        }
    },[formik.values])
    const handleSieveMenuItemClick = (index) => (event) => {
       
        // Update the selected item index
        setSelectedSieveItemIndex(index);
    
        // Update the sieve range value
        setSieveRangeValue([
          sieveSizeData[index].sieve_mm_id,
          sieveSizeData[index].sieve_mm_id, // Assuming sieve_mm_id represents the to value
        ]);
       
    };
    const handleSizeMenuItemClick = (index) => (event) => {
       
        // Update the selected item index
        setSelectedSizeItemIndex(index);
    
        // Update the sieve range value
        setSizeRangeValue([
          sieveSizeData[index].sieve_mm_id,
          sieveSizeData[index].sieve_mm_id, // Assuming sieve_mm_id represents the to value
        ]);
       
    };

    useEffect(() => {
        // setSieveRangeValue(formik.values.range_value_from ? formik.values.range_value_from : 1 , formik.values.range_value_to ? formik.values.range_value_to : 63)
        formik.setFieldValue("range_value_from", minSieve);
        formik.setFieldValue("range_value_to", maxSieve);
        axios({
            method: "GET",
            url: `${NODE_API_URL}/IVP/sieve-or-size`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
        })
        .then(function(res) {
        //   console.log(res)
            if(res && res.data){
                setSieveSizeData(res.data.data)
// console.log("ressize",res.data.data)

                setLastIndex(res.data.data.length)
            }
        })
        .catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        
    },[]);
// console.log("sieveSizeData",sieveSizeData)
    useEffect(() => {
        axios({
            method: "get",
            url: `${NODE_API_URL}/IVP/parcelSieveOrSizeViceVerse`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params: {
                "range_from":sieveRangeValue[0],
                "range_to":sieveRangeValue[1]
            },
        })
        .then(function(res) {
            // console.log(res.data.data[0])
            if(res && res.data){
                setMinSieveText(res.data.data[0].sieve_from)
                setMaxSieveText(res.data.data[1].sieve_to)
                setMinSizeText(res.data.data[0].mm_from)
                setMaxSizeText(res.data.data[1].mm_to)
                setMinWeight(res.data.data[0].weight)
                setMaxWeight(res.data.data[1].weight)
                // setSieveSizeData(res.data.data)
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
       
    },[minSieve,maxSieve,minSize,maxSize,sieveRangeValue]);

    const handleSieveRangeChange = (event, newValue) => {
            //  console.log("newValue",newValue)
   
        setMinSize(newValue[0]);
        setMaxSize(newValue[1]);
        setSieveRangeValue(newValue);
        setMinSieve(newValue[0]);
        setMaxSieve(newValue[1]);
        setSizeRangeValue(newValue);
        formik.setFieldValue("range_value_from", newValue[0]);
        formik.setFieldValue("range_value_to", newValue[1]);
        formik.setFieldValue("carat_value", "");
        formik.setFieldValue("pcs_value", "");
        // console.log("newValue",newValue)
        setRangeErr(false)

        // Map the slider value to an index of the menu items
        // const indexSieve = Math.round(((newValue[0] - minSiveRange) / (maxSiveRange - minSiveRange)) * (sieveSizeData.length - 1));
        // Update the selected item index
        
        // Map the slider value to an index of the menu items
        const indexSize = Math.round(((newValue[0] - minSizeRange) / (maxSizeRange - minSizeRange)) * (sieveSizeData.length - 1));
        // Update the selected item index
        setSelectedSieveItemIndex(indexSize);
        setSelectedSizeItemIndex(indexSize);
    };
    
      // Scroll to the selected menu item when it changes
    useEffect(() => {
        if (menuRefSieve.current && selectedSieveItemIndex !== null) {
            const menuItem = menuRefSieve.current.children[selectedSieveItemIndex];
            if (menuItem) {
                menuItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                });
            }
        }
    }, [selectedSieveItemIndex]);

         // Scroll to the selected menu item when it changes
    useEffect(() => {
        if (menuRefSize.current && selectedSizeItemIndex !== null) {
            const menuItem = menuRefSize.current.children[selectedSizeItemIndex];
            if (menuItem) {
                menuItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                });
            }
        }
    }, [selectedSizeItemIndex]);

    const sieveBoxFn = () => {
        setSieveBox(true);
        setSizeBox(false);
     
    };

    const sizeBoxFn = () => {
        setSieveBox(false);
        setSizeBox(true);
      
    };

    return (
        <>
       {/* { console.log(sieveRangeValue)} */}
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-12 mt-5 mb-10 d-flex justify-content-between align-items-center">
                    <div>
                        <label className="search_btn_check">
                            <Field
                                type="radio"
                                name="sieveSize"
                                checked={sizeBox}
                                onClick={(e) => sizeBoxFn()}
                            />
                            <div className="parcelBox selectBox">
                                <span>Size(MM)</span>
                            </div>
                        </label>
                        <label className="search_btn_check">
                            <Field
                                type="radio"
                                name="sieveSize"
                                checked={sieveBox}
                                onClick={(e) => sieveBoxFn()}
                            />
                            <div className="parcelBox selectBox">
                                <span>Sieve</span>
                            </div>
                        </label>
                       
                    </div>
                    <div>
                        {sieveBox ? (
                            <div className="font-weight-bold small"> {language.PARCEL_DIAMONDS_SIZE}(MM) : {minSizeText} to {maxSizeText} </div>
                            ) : (
                            <div className="font-weight-bold small"> {language.PARCEL_DIAMONDS_SIEVE} : +{minSieveText} to -{maxSieveText} </div>
                        )}
                        <div className="font-weight-bold small"> {language.PARCEL_DIAMONDS_WEIGHT} : {parseFloat(minWeight).toFixed(2)} to {parseFloat(maxWeight).toFixed(2)} </div>
                    </div>
                </div>
                {sieveBox ? (
                    <div className="col-12 ">
                        <div className="row">
                            <div className="col-md-12">
                                <Slider
                                    getAriaLabel={() => "Sieve range"}
                                    value={sieveRangeValue}
                                    onChange={handleSieveRangeChange}
                                    min={minSiveRange}
                                    max={maxSiveRange}
                                    
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Select
                                    ref={menuRefSieve}
                                    margin="dense"
                                    MenuProps={MenuProps}
                                    name="minSieve"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[0]}
                                    onChange={(e) => {
                                        setMinSieve(Number(e.target.value));
                                        setRangeErr(false);
                                        setSieveRangeValue([
                                            Number(e.target.value),
                                            sieveRangeValue[1],
                                        ]);
                                        // console.log("from sieve",e)
                                        formik.setFieldValue("range_value_from", Number(e.target.value));
                                        formik.setFieldValue("carat_value", "");
                                        formik.setFieldValue("pcs_value", "");
                                    }}
                                >
                                    {sieveSizeData.length !== 0 ? sieveSizeData.map((option,index) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                            onClick={handleSieveMenuItemClick(index)}
                                            autoFocus={index === selectedSieveItemIndex}
                                        >
                                            +{option.sieve_from}
                                        </MenuItem>
                                     )) : <MenuItem value={"00000"} > +00000 </MenuItem> }
                                </Select>
                                <Select
                                    margin="dense"
                                    variant="outlined"
                                    MenuProps={MenuProps}
                                    name="maxSieve"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[1]}
                                    onChange={(e) => {
                                        setMaxSieve(Number(e.target.value));
                                        setRangeErr(false);
                                        setSieveRangeValue([
                                            sieveRangeValue[0],
                                            Number(e.target.value),
                                        ]);
                                         // Find the index of the selected option
                                        const selectedIndex = sieveSizeData.findIndex(option => option.sieve_mm_id === Number(e.target.value));

                                        // Update the selected item index
                                        setSelectedSieveItemIndex(selectedIndex);
                                        // console.log("to sieve",Number(e.target.value))
                                        formik.setFieldValue("range_value_to", Number(e.target.value));
                                        formik.setFieldValue("carat_value", "");
                                        formik.setFieldValue("pcs_value", "");
                                    }}
                                >
                                    {sieveSizeData.length !==0 ? sieveSizeData.map((option,index) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                            onClick={handleSieveMenuItemClick(index)}
                                            autoFocus={index === sieveRangeValue[1]}
                                        >
                                            -{option.sieve_to}
                                        </MenuItem>
                                     )) : <MenuItem value={19} > -19 </MenuItem> }
                                </Select>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {sizeBox ? (
                    <div className="col-12 ">
                        <div className="row">
                            <div className="col-md-12">
                                <Slider
                                    getAriaLabel={() => "Size range"}
                                    value={sieveRangeValue}
                                    onChange={handleSieveRangeChange}
                                    min={minSizeRange}
                                    max={maxSizeRange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Select
                                    ref={menuRefSize}
                                    MenuProps={MenuProps}
                                    margin="dense"
                                    name="minSize"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[0]}
                                    onChange={(e) => {
                                        setMinSize(Number(e.target.value));
                                        setRangeErr(false);
                                        setSieveRangeValue([
                                            Number(e.target.value),
                                            sieveRangeValue[1],
                                        ]);
                                        // console.log("from mm",Number(e.target.value))
                                        formik.setFieldValue("range_value_from", Number(e.target.value));
                                        formik.setFieldValue("carat_value", "");
                                        formik.setFieldValue("pcs_value", "");
                                    }}
                                >
                                    {sieveSizeData.length !==0 ? sieveSizeData.map((option,index) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                            onClick={handleSizeMenuItemClick(index)}
                                            autoFocus={index === selectedSizeItemIndex}
                                        >
                                            {option.mm_from} MM
                                        </MenuItem>
                                    )) : <MenuItem value={0.7} > 0.7 MM</MenuItem> }
                                </Select>
                                <Select
                                    MenuProps={MenuProps}
                                    margin="dense"
                                    variant="outlined"
                                    name="maxSize"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[1]}
                                    onChange={(e) => {
                                        setMaxSize(Number(e.target.value));
                                        setRangeErr(false);
                                        setSieveRangeValue([
                                            sieveRangeValue[0],
                                            Number(e.target.value),
                                        ]);
                                          // Find the index of the selected option
                                          const selectedIndex = sieveSizeData.findIndex(option => option.sieve_mm_id === Number(e.target.value));

                                          // Update the selected item index
                                          setSelectedSizeItemIndex(selectedIndex);
                                        // console.log("to mm",Number(e.target.value))
                                        formik.setFieldValue("range_value_to", Number(e.target.value));
                                        formik.setFieldValue("carat_value", "");
                                        formik.setFieldValue("pcs_value", "");
                                    }}
                                >
                                    {sieveSizeData.length !== 0 ? sieveSizeData.map((option,index) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                            onClick={handleSieveMenuItemClick(index)}
                                            autoFocus={index === sieveRangeValue[1]}
                                        >
                                            {option.mm_to} MM
                                        </MenuItem>
                                    )) : <MenuItem value={4.3} > 4.3 MM</MenuItem> }
                                </Select>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div className="col-12">
                    {/* {formik.values.range_value_from ==='' || formik.values.range_value_to ===''  ? (
                        <div className="text-danger">
                            please select size or sieve
                        </div>
                    ) : null} */}
                    {rangeErr  ? (
                        <div className="text-danger">
                            please select size or sieve
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};
