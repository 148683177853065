import React, { Suspense} from "react";
import { useIdleTimer } from 'react-idle-timer'

import { Redirect, Switch, } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import {PreviewIframe} from "../_metronic/_partials/settings/Iframe/PreviewIframe"
import "../assets/css/BackendStyle.css"
import { SearchDiamond , LabSearchDiamond,SearchResult, Profile, ConfirmGoods, HoldDiamonds,Invoice,InviceDetail,ProformaDetails,Settings,Contact,TransparentPricing,Ccmode,DiamondFeed,ApiDocumentationVip,ApiDocumentation,Checkout,Comparison,ParcelDiamSearch} from "../_metronic/_partials";
import { CcmodeSettingNew } from "../_metronic/_partials/ccmode2.0/CcmodeSettingNew";
import { ShortListDetail } from "../_metronic/_partials/ccmode2.0/ShortList/ShortListDetail/ShortListDetail";
import { ShortListTableByMail } from "../_metronic/_partials/DiamondFeedNew/ShortList/shortListByMail/ShortListTableByMail";
import SearchGrid from "../_metronic/_partials/tables/SearchGrid";
import DetailPage from "../_metronic/_partials/detailPage/DetailPage";
import DetailPageHC from "../_metronic/_partials/detailPage/DetailPageHC";
import { Wishlist } from "../_metronic/_partials/tables/WishlistPage";
import { BASE_URL } from "../env_config";
import IframeAPI from "../_metronic/_partials/widgets/tiles/IframeAPI";
import IframeAPILab from "../_metronic/_partials/widgets/tiles/IframeAPILab";
import { ToastContainer } from 'react-toastify';
import { PaymentFailed } from "../_metronic/_partials/checkout/PaymentFailed";
import { PaymentSuccess } from "../_metronic/_partials/checkout/PaymentSuccess";
import WishlistGrid from "../_metronic/_partials/tables/WishlistGrid";
// import SubscriptionPage from "../_metronic/_partials/apiv3/SubscriptionPage";
import ApiRequested from "../_metronic/_partials/apiv3/ApiRequested";
import ApiUpdateForm from "../_metronic/_partials/DiamondFeedNew/ApiSetup/ApiUpdateForm";
import ApiSetupFrom from "../_metronic/_partials/DiamondFeedNew/ApiSetup/ApiSetupFrom";
import ApiCopyForm from "../_metronic/_partials/DiamondFeedNew/ApiSetup/ApiCopyForm";
// import { FeedApproved } from "../_metronic/_partials/DiamondFeed";
import { FeedApprovedFrame } from "../_metronic/_partials/DiamondFeedNew";
import { SearchResultChurned } from "../_metronic/_partials/tables/SearchResultChurned";
import { DashboardChurned } from "../_metronic/_partials/dashboards/DashboardChurned";
// import { ProfileUnderReview } from "../_metronic/_partials/dashboards/ProfileUnderReview";
import CcmodeRule from "../_metronic/_partials/ccmode2.0/CcmodeRule/CcmodeRule";
import { ApiV3ViewDataNatSup } from "../_metronic/_partials/DiamondFeed/ApiDataTable/ApiV3ViewDataNatSup";
import IframeRule from "../_metronic/_partials/DiamondFeedNew/IframeRule/IFrameRule";
import { CCModeViewDataSup } from "../_metronic/_partials/DiamondFeedNew/ApiDataTable/CCModeViewDataSup";
import { IframeSettingNew } from "../_metronic/_partials/iframe2.0/IframeSettingNew";
import { PreviewIframe2 } from "../_metronic/_partials/settings/Iframe/PreviewIframe2";
// import { TracklistTable } from "../_metronic/_partials/tables/TracklistTable";
import { TracklistMaster } from "../_metronic/_partials/tables/TracklistMaster";
import CCModeSetupFrom from "../_metronic/_partials/ccmode2.0/CCModeSetup/CCModeSetupFrom";
import CCModeUpdateForm from "../_metronic/_partials/ccmode2.0/CCModeSetup/CCModeUpdateForm";
import CCModeCopyForm from "../_metronic/_partials/ccmode2.0/CCModeSetup/CCModeCopyForm";
export default function BasePage({language,conversionrate,symbol,isprofilecomplete,userinfo,localstored,dataFetchedRef,symbolname,logStoredRef,setConversionCookies,conversioncookies}) {

// const [state, setState] = useState('Active')
// const [count, setCount] = useState(0)
// const [remaining, setRemaining] = useState(0)

const onIdle = () => {
  // setState('Idle')
  localStorage.clear()
    var cookies = document.cookie.split(";");
for(var i=0; i < cookies.length; i++) {
  if(cookies[i] && !cookies[i].includes("view")){
    var equals = cookies[i].indexOf("=");
    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
    window.location.replace(`${BASE_URL}/login/logout`);
    window.location.reload(true)
}

const onActive = () => {
}

const onAction = () => {
}

const { getRemainingTime } = useIdleTimer({
  onIdle,
  onActive,
  onAction,
  timeout: 3600000,
  throttle: 500
})

// useEffect(() => {
//   const interval = setInterval(() => {
//     setRemaining(Math.ceil(getRemainingTime() / 1000))
//   }, 500)

//   return () => {
//     clearInterval(interval)
//   }
// })
  return (
    <>
    {/* <button type="button" className="btn btn-primary px-10 mr-3 btn-pill font-weight-bold" onClick={() => fullscreen()}>
      Enter/Exit Full Screen
    </button> */}
    {localstored?<Suspense fallback={<LayoutSplashScreen />}>
      <>
    {userinfo.v_customer === "12"?<Switch>
      {
        /* Redirect from root URL to /dashboard. */
        <Redirect exact from="/" to="/dashboard" />
      }
      
      <ContentRoute path="/dashboard" component={() => <DashboardChurned language={language} symbol={symbol} conversionrate={conversionrate} isprofilecomplete={isprofilecomplete} userinfo={userinfo}/>} />
      <ContentRoute path="/profile"  component={() => <Profile language={language} isprofilecomplete={isprofilecomplete} userinfo={userinfo}  setConversionCookies={setConversionCookies} conversioncookies={conversioncookies}/> }/>
      <ContentRoute path="/settings"  component={() => <Settings language={language} userinfo={userinfo}/>}/>
      {userinfo.kyc_status === "KYC_APPR"?<>
      <ContentRoute path="/shipment-search-result/:diamondType" component={() => <SearchResultChurned language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname} userinfo={userinfo}/>} isprofilecomplete={isprofilecomplete}/>
      <ContentRoute path="/confirmgoods"  component={() => <ConfirmGoods userinfo={userinfo} language={language} conversionrate={conversionrate} symbol={symbol}/>}/>
      <ContentRoute path="/invoices"  component={() => <Invoice  language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/invoice-detail/:OrderId"  component={() => <InviceDetail language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/proforma-detail"  component={() => <ProformaDetails language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/checkout" component={() => <Checkout language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/payment-failed" component={() => <PaymentFailed language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/payment-success" component={() => <PaymentSuccess language={language} userinfo={userinfo} dataFetchedRef={dataFetchedRef}/>}/>
      </>:""}
      <Redirect to="error/error-v4" />
    </Switch>:<Switch>
      {
        /* Redirect from root URL to /dashboard. */
        <Redirect exact from="/" to="/dashboard" />
      }
      
      <ContentRoute path="/dashboard" component={() => <DashboardPage language={language} symbol={symbol} conversionrate={conversionrate} isprofilecomplete={isprofilecomplete} userinfo={userinfo}/>} />
      <ContentRoute path="/searchdiamond" component={() => <SearchDiamond language={language} userinfo={userinfo} symbol={symbol} conversionrate={conversionrate}/>} />
      <ContentRoute path="/parcel-diamond" component={() => <ParcelDiamSearch language={language} userinfo={userinfo} symbol={symbol} conversionrate={conversionrate}/>} />
      <ContentRoute path="/lab-parcel-diamond" component={() => <ParcelDiamSearch language={language} userinfo={userinfo} symbol={symbol} conversionrate={conversionrate}/>} />
      <ContentRoute path="/labsearchdiamond" component={() => <LabSearchDiamond language={language} userinfo={userinfo} symbol={symbol} conversionrate={conversionrate}/>}/>
      <ContentRoute path="/search-result/:diamondType" component={() => <SearchResult language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname} userinfo={userinfo}/>} isprofilecomplete={isprofilecomplete}/>
      <ContentRoute path="/wishlist" component={() => <Wishlist userinfo={userinfo} language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname}/>} isprofilecomplete={isprofilecomplete}/>
      <ContentRoute path="/search-grid/:diamondType" component={() => <SearchGrid userinfo={userinfo} language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname}/>} isprofilecomplete={isprofilecomplete}/>
      <ContentRoute path="/detail/:ProductId"  component={() => <DetailPage language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname} userinfo={userinfo} logStoredRef={logStoredRef}/>}/>
      <ContentRoute path="/details/:OrderId"  component={() => <DetailPageHC language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname} userinfo={userinfo} logStoredRef={logStoredRef}/>}/>
      <ContentRoute path="/profile"  component={() => <Profile language={language} isprofilecomplete={isprofilecomplete} userinfo={userinfo}  setConversionCookies={setConversionCookies} conversioncookies={conversioncookies}/> }/>
      <ContentRoute path="/confirmgoods"  component={() => <ConfirmGoods userinfo={userinfo} language={language} conversionrate={conversionrate} symbol={symbol}/>}/>
      <ContentRoute path="/hold-diamonds"  component={() => <HoldDiamonds userinfo={userinfo} language={language} conversionrate={conversionrate} symbol={symbol}/>}/>
      <ContentRoute path="/invoices"  component={() => <Invoice language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/invoice-detail/:OrderId"  component={() => <InviceDetail language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/proforma-detail"  component={() => <ProformaDetails language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/settings"  component={() => <Settings language={language} userinfo={userinfo}/>}/>
      {/* <ContentRoute path="/contact"  component={() => <Contact language={language}/>}/> */}
      <ContentRoute path="/transparentpricing" component={() => <TransparentPricing language={language} userinfo={userinfo}/>} />
      <ContentRoute path="/ccmode" component={() => <Ccmode language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/diamondfeed" component={() => <DiamondFeed language={language} userinfo={userinfo}  setConversionCookies={setConversionCookies} conversioncookies={conversioncookies} conversionrate={conversionrate} symbol={symbol}/>}/>
      <ContentRoute path="/iframe-preview" component={() => <PreviewIframe language={language}/>}/>
      <ContentRoute path="/api-documentation-vip" component={() => <ApiDocumentationVip language={language}/>}/>
      <ContentRoute path="/api-documentation" component={() => <ApiDocumentation language={language}/>}/>
      <ContentRoute path="/iframe-natural" component={() => <IframeAPI language={language}/>}/>
      <ContentRoute path="/iframe-lab" component={() => <IframeAPILab language={language}/>}/>
      <ContentRoute path="/checkout" component={() => <Checkout language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/payment-failed" component={() => <PaymentFailed language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/payment-success" component={() => <PaymentSuccess language={language} userinfo={userinfo} dataFetchedRef={dataFetchedRef}/>}/>
      <ContentRoute path="/comparison" component={() => <Comparison language={language} userinfo={userinfo} conversionrate={conversionrate} symbolname={symbolname} symbol={symbol}/>}/>
      {/* <ContentRoute path="/matching-pair" component={() => <MatchingPair language={language} userinfo={userinfo} conversionrate={conversionrate} symbol={symbol} />}/> */}
      <ContentRoute path="/wishlist-grid" component={() => <WishlistGrid language={language} conversionrate={conversionrate} symbol={symbol}/>} isprofilecomplete={isprofilecomplete}/>
      <ContentRoute path="/api-v3" component={() => <ApiRequested/>}/>
      <ContentRoute path="/update-rule" component={() => <ApiUpdateForm language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/create-rule" component={() => <ApiSetupFrom language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/copy-rule" component={() => <ApiCopyForm language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/rule-suppliers" component={() => <ApiV3ViewDataNatSup language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/supplier-management" component={() => <FeedApprovedFrame language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/supplier-management-iframe" component={() => <FeedApprovedFrame language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      {/* <ContentRoute path="/ccmode-create-rule" component={() => <CcmodeRule language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/ccmode-update-rule" component={() => <CcmodeRule language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/> */}
      <ContentRoute path="/ccmode-setting" component={() => <CcmodeSettingNew language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/ccmode-shortlist" component={() => <ShortListDetail language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/iframe-shortlist-user" component={() => <ShortListTableByMail language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/iframe-create-rule" component={() => <IframeRule language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/iframe-update-rule" component={() => <IframeRule language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/iframe-rule-suppliers" component={() => <CCModeViewDataSup language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/iframe-setting" component={() => <IframeSettingNew language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/iframe2.0-preview" component={() => <PreviewIframe2 language={language}/>}/>
      <ContentRoute path="/tracklist" component={() => <TracklistMaster language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/ccmode-create-rule" component={() => <CCModeSetupFrom  language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/ccmode-update-rule" component={() => <CCModeUpdateForm  language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/ccmode-copy-rule" component={() => <CCModeCopyForm  language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <Redirect to="error/error-v4" />
    </Switch>}
    </>
  </Suspense>:""}
  <ToastContainer />
  </>
  );
}
