import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import {useLocation} from "react-router-dom"
import { MockShortListColumn } from './ApiV3NewSupColumns'; 
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight} from "react-icons/fa";
// import { CheckBox } from './CheckBox';
import ApiDataSkeleton from './ApiDataSkeleton'
// import  {ExpandedDetails} from "./Expand/ExpandedDetails"
import axios from 'axios';
import { NODE_API_URL } from '../../../../env_config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SwalWarn } from '../../Popup';
import useStyles from '../ApiSetup/styles';
import {
    Button,
    withStyles
  } from '@material-ui/core';
  import red from "material-ui/colors/red";
import { BsArrowLeftShort } from 'react-icons/bs';
import SupplierSortingDropdown from '../SupplierSortingDropdown.js';
import Cookies from "js-cookie"

export const ApiV3ViewDataNatSup = (props) => {
    const ErrorButton = withStyles(theme => ({

        root: {
    
          color: theme.palette.getContrastText(red[500]),
    
          backgroundColor: red[500],
    
          '&:hover': {
    
            backgroundColor: red[700],
    
          },
    
        },
    
      }))(Button);
      const classes = useStyles();
    const [swalopen,setSwalOpen] = useState(false)
    const location = useLocation()
    console.log(location,"locationlocation")
    const [supplierchanged,setSupplierChanged] = useState(false)
    const [supplierdata,setSupplierData] = useState([])
    const [allsupplierdata,setAllSupplierData] = useState([])
    const [updateapi,setupdateApi] = useState(false)
    const [pagenumber,setPageNumber] = useState(1)
    console.log(pagenumber,"pagenumberpagenumber")
    // console.log(props.userinfo.api_id,"ApiDataNatural")
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    // const renderRowSubComponent = React.useCallback(
    //     (row) => (
    //         <>
    //             <ExpandedDetails row={row}/>
    //         </>
    //     ),[]
    //   )
    const [update,setUpdate] = useState(false)
    const [stockexpire,setStockExpire] = useState([])
    const [loading,setLoading] = useState(false)
    // console.log(stockexpire,"stockexpire")
    // const data = useMemo(()=> StockAPI,[])
    const [erpireddate,setExpiredDate] = useState([])
    const [data,setData] = useState([])
    const [countloader,setCountLoader] = useState(false)
    const [sorting,setSorting] = useState("")
    console.log(sorting,"sorting")
    useEffect(() => {
        setLoading(true)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/fetch-Rule-ByName`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
              },
            data: {
                user_id:props.userinfo.id,
                rule_name:location.state.rule_name
            },
        }).then(ruleres => {
            if(ruleres && ruleres.data && ruleres.data.success){
                let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        if(location.state.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(location.state.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(location.state.labdiamond){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(location.state.labfancydiamond){
          diamondtype = "L"
          lgfancydiam = 1
        }
        console.log(location.state.api_id,"location.state.api_id")
        let FinalObject = {
            "user_id":props.userinfo.id,
            "api_id":location.state.api_id,  
          diamond_type:diamondtype,
          naturaldiamond:naturaldiamond,
          naturalfancydiamond:naturalfancydiamond,
          labdiamond:lgwhitediam,
          labfancydiamond:lgfancydiam,
          rule_name:location.state.rule_name,
          user_id:props.userinfo.id,
        //   suppliers:props.values.suppliers,
          api_currency:props.userinfo.api_currency,
          "rule_id":ruleres.data.data.rule_id,
          page:pagenumber
        }
        if(sorting === "supplier_asc"){
            FinalObject["sortcolumn"] = "supplier_name"
            FinalObject["sortby"] = "ASC"
        }
        if(sorting === "supplier_desc"){
            FinalObject["sortcolumn"] = "supplier_name"
            FinalObject["sortby"] = "DESC"
        }
        if(sorting === "count_asc"){
            FinalObject["sortby"] = "ASC"
            if(location.state.naturaldiamond){
                FinalObject["sortcolumn"] = "whitetotal"
              }
              if(location.state.naturalfancydiamond){
                FinalObject["sortcolumn"] = "fancytotal"
              }
              if(location.state.labdiamond){
                FinalObject["sortcolumn"] = "whitetotal"
              }
              if(location.state.labfancydiamond){
                FinalObject["sortcolumn"] = "fancytotal"
              }
        }
        if(sorting === "count_desc"){
            FinalObject["sortby"] = "DESC"
            if(location.state.naturaldiamond){
                FinalObject["sortcolumn"] = "whitetotal"
              }
              if(location.state.naturalfancydiamond){
                FinalObject["sortcolumn"] = "fancytotal"
              }
              if(location.state.labdiamond){
                FinalObject["sortcolumn"] = "whitetotal"
              }
              if(location.state.labfancydiamond){
                FinalObject["sortcolumn"] = "fancytotal"
              }
        }
        // if(props.values.markupoption === "Dollar"){
        //   FinalObject["markupdollar"] = parseFloat(props.values.markup)
        // }
        // else{
        //   FinalObject["markupperc"] = parseFloat(props.values.markup)
        //   FinalObject["currency"] = props.values.multi_currency
        // }
        if(diamondtype === "N"){
            FinalObject["shape"] = location.state.shape?location.state.shape.split(','):null
            FinalObject["cut"] = location.state.cut?location.state.cut.split(','):null
            FinalObject["clarity"] = location.state.clarity?location.state.clarity.split(','):null
            FinalObject["min_carat"] = parseFloat(location.state.min_carat)
            FinalObject["max_carat"] = parseFloat(location.state.max_carat)
            FinalObject["lab"] = location.state.lab?location.state.lab.split(','):null
            FinalObject["symmetry"] = location.state.symmetry?location.state.symmetry.split(','):null
            FinalObject["fluorescence"] = location.state.fluorescence?location.state.fluorescence.split(','):null
            FinalObject["polish"] = location.state.polish?location.state.polish.split(','):null
            FinalObject["milky"] = location.state.milky?location.state.milky.split(','):null
            FinalObject["eyeclean"] = location.state.eyeclean?location.state.eyeclean.split(','):null
            FinalObject["shade"] = location.state.shade?location.state.shade.split(','):null
            FinalObject["min_dollarperct"] = parseFloat(location.state.min_dollarperct)
            FinalObject["max_dollarperct"] = parseFloat(location.state.max_dollarperct)
            FinalObject["total_price_from"] = parseFloat(location.state.total_price_from)
            FinalObject["total_price_to"] = parseFloat(location.state.total_price_to)
            FinalObject["media"] = location.state.media?location.state.media.split(','):null
            FinalObject["minlength"] = parseFloat(location.state.minlength)
            FinalObject["maxlength"] = parseFloat(location.state.maxlength)
            FinalObject["minwidth"] = parseFloat(location.state.minwidth)
            FinalObject["maxwidth"] = parseFloat(location.state.maxwidth)
            FinalObject["minheight"] = parseFloat(location.state.minheight)
            FinalObject["maxheight"] = parseFloat(location.state.maxheight)
            FinalObject["tablemin"] = parseFloat(location.state.tablemin)
            FinalObject["tablemax"] = parseFloat(location.state.tablemax)
            FinalObject["depthmin"] = parseFloat(location.state.depthmin)
            FinalObject["depthmax"] = parseFloat(location.state.depthmax)
            FinalObject["ratiomin"] = parseFloat(location.state.ratiomin)
            FinalObject["ratiomax"] = parseFloat(location.state.ratiomax)
            FinalObject["crheightmin"] = parseFloat(location.state.crheightmin)
            FinalObject["crheightmax"] = parseFloat(location.state.crheightmax)
            FinalObject["cranglemin"] = parseFloat(location.state.cranglemin)
            FinalObject["cranglemax"] = parseFloat(location.state.cranglemax)
            FinalObject["pavheightmin"] = parseFloat(location.state.pavheightmin)
            FinalObject["pavheightmax"] = parseFloat(location.state.pavheightmax)
            FinalObject["pavanglemin"] = parseFloat(location.state.pavanglemin)
            FinalObject["pavanglemax"] = parseFloat(location.state.pavanglemax)
            FinalObject["brand"] = location.state.brand?location.state.brand.split(','):null
            FinalObject["origin"] = location.state.origin?location.state.origin.split(','):null
            FinalObject["treatment"] = location.state.treatment?location.state.treatment.split(','):null
            FinalObject["keytosymbol"] = location.state.keytosymbol?location.state.keytosymbol.split(','):null
            if(naturaldiamond){
              FinalObject["color"] = location.state.color?location.state.color.split(','):null
            }
            else{
              FinalObject["fancy_color"] = location.state.diamondfancy_color?location.state.diamondfancy_color.split(','):null
              FinalObject["fancy_intensity"] = location.state.diamondfancy_intensity?location.state.diamondfancy_intensity.split(','):null
              FinalObject["fancy_overtone"] = location.state.diamondfancy_overtone?location.state.diamondfancy_overtone.split(','):null
            }
        }
        else{
          FinalObject["shape"] = location.state.lab_shape?location.state.lab_shape.split(','):null
          FinalObject["cut"] = location.state.lab_cut?location.state.lab_cut.split(','):null
          FinalObject["clarity"] = location.state.lab_clarity?location.state.lab_clarity.split(','):null
          FinalObject["min_carat"] = parseFloat(location.state.lab_min_carat)
          FinalObject["max_carat"] = parseFloat(location.state.lab_max_carat)
          FinalObject["lab"] = location.state.lab_lab?location.state.lab_lab.split(','):null
          FinalObject["symmetry"] = location.state.lab_symmetry?location.state.lab_symmetry.split(','):null
          FinalObject["fluorescence"] = location.state.lab_fluorescence?location.state.lab_fluorescence.split(','):null
          FinalObject["polish"] = location.state.lab_polish?location.state.lab_polish.split(','):null
          FinalObject["milky"] = location.state.lab_milky?location.state.lab_milky.split(','):null
          FinalObject["eyeclean"] = location.state.lab_eyeclean?location.state.lab_eyeclean.split(','):null
          FinalObject["shade"] = location.state.lab_shade?location.state.lab_shade.split(','):null
          FinalObject["min_dollarperct"] = parseFloat(location.state.lab_min_dollarperct)
          FinalObject["max_dollarperct"] = parseFloat(location.state.lab_max_dollarperct)
          FinalObject["total_price_from"] = parseFloat(location.state.lab_total_price_from)
          FinalObject["total_price_to"] = parseFloat(location.state.lab_total_price_to)
          FinalObject["media"] = location.state.lab_media?location.state.lab_media.split(','):null
          FinalObject["minlength"] = parseFloat(location.state.labminlength)
            FinalObject["maxlength"] = parseFloat(location.state.labmaxlength)
            FinalObject["minwidth"] = parseFloat(location.state.labminwidth)
            FinalObject["maxwidth"] = parseFloat(location.state.labmaxwidth)
            FinalObject["minheight"] = parseFloat(location.state.labminheight)
            FinalObject["maxheight"] = parseFloat(location.state.labmaxheight)
            FinalObject["tablemin"] = parseFloat(location.state.labtablemin)
            FinalObject["tablemax"] = parseFloat(location.state.labtablemax)
            FinalObject["depthmin"] = parseFloat(location.state.labdepthmin)
            FinalObject["depthmax"] = parseFloat(location.state.labdepthmax)
            FinalObject["ratiomin"] = parseFloat(location.state.labratiomin)
            FinalObject["ratiomax"] = parseFloat(location.state.labratiomax)
            FinalObject["crheightmin"] = parseFloat(location.state.labcrheightmin)
            FinalObject["crheightmax"] = parseFloat(location.state.labcrheightmax)
            FinalObject["cranglemin"] = parseFloat(location.state.labcranglemin)
            FinalObject["cranglemax"] = parseFloat(location.state.labcranglemax)
            FinalObject["pavheightmin"] = parseFloat(location.state.labpavheightmin)
            FinalObject["pavheightmax"] = parseFloat(location.state.labpavheightmax)
            FinalObject["pavanglemin"] = parseFloat(location.state.labpavanglemin)
            FinalObject["pavanglemax"] = parseFloat(location.state.labpavanglemax)
            FinalObject["brand"] = location.state.labbrand?location.state.labbrand.split(','):null
            FinalObject["origin"] = location.state.laborigin?location.state.laborigin.split(','):null
            FinalObject["labtreatment"] = location.state.labtreatment?location.state.labtreatment.split(','):null
            FinalObject["keytosymbol"] = location.state.labkeytosymbol?location.state.labkeytosymbol.split(','):null
          if(lgwhitediam){
            FinalObject["color"] = location.state.lab_color?location.state.lab_color.split(','):null
          }
          else{
            FinalObject["fancy_color"] = location.state.lab_fancy_color?location.state.lab_fancy_color.split(','):null
            FinalObject["fancy_intensity"] = location.state.lab_fancy_intensity?location.state.lab_fancy_intensity.split(','):null
            FinalObject["fancy_overtone"] = location.state.lab_fancy_overtone?location.state.lab_fancy_overtone.split(','):null
          }
        }
        console.log(FinalObject,"FinalObject")
        // const FinalObject = {
        //     "user_id":props.userinfo.id,
        //     "api_id":props.userinfo.api_id,
        //     "diamond_type":diamondtype
        // }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/fetch-Supplier-RulesNew`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
              },
            data: FinalObject,
        }).then(res => {
            console.log(res.data,"res.data")
            if(res && res.data && res.data === "Please Turn On Suppliers"){
                setLoading(false)

            }
            if(res && res.data && res.data.data){
                // setData(res.data.data)
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/IVP/fetch-Supplier-Rule-Request`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                      },
                    data: {
                        "user_id":props.userinfo.id,
                        "rule_id":ruleres.data.data.rule_id
                    },
                }).then(supreq => {
                    setLoading(false)

                    if(supreq && supreq.data && supreq.data.data){
                        // setData(res.data.data)
                        let merged = [];

                        // for (let i = 0; i < supreq.data.data.length; i++) {
                        //     merged.push({
                        //         ...supreq.data.data[i],
                        //         ...(res.data.data.find((itmInner) => itmInner.id === supreq.data.data[i].supplier_id))
                        //     }
                        //     );
                        // }
                        // let count = 0
                        for (let i = 0; i < res.data.data.length; i++) {
                            // if(res.data.data[i].whitetotal){
                            //     count += res.data.data[i].whitetotal
                            // }
                            // if(res.data.data[i].fancytotal){
                            //     count += res.data.data[i].fancytotal
                            // }
                            const fetcheddata = {...(supreq.data.data.find((itmInner) => itmInner.supplier_name === res.data.data[i].supplier_name))} 
                            console.log(fetcheddata,"fetcheddata")
                            merged.push({
                                ...res.data.data[i],
                                ...fetcheddata
                            }
                            );
                            // let mergedobj = {
                            //     ...res.data.data[i],
                            //     ...fetcheddata
                            // }
                            // if((props.values.naturaldiamond || props.values.lgwhitediam) && mergedobj.whitetotal && mergedobj.api_on_off === 1){
                            //     count += mergedobj.whitetotal
                            // }
                            // if((props.values.naturalfancydiamond || props.values.lgfancydiam) && mergedobj.fancytotal && mergedobj.api_on_off === 1){
                            //     count += mergedobj.fancytotal
                            // }
                        }
                        // console.log(merged,"merged")
                        merged = merged.filter(value => {
                            if(value.supplier_name){
                                return value
                            }
                        })
                        setData(merged,"merged")
                        // setTotalCount(count)
                        setCountLoader(true)
                        let rulediamondtype = ""
                        if(naturaldiamond){
                            rulediamondtype = "N"
                        }
                        if(naturalfancydiamond){
                            rulediamondtype = "NF"
                        }
                        if(lgwhitediam){
                            rulediamondtype = "L"
                        }
                        if(lgfancydiam){
                            rulediamondtype = "LF"
                        }
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/IVP/stone-Count-By-RuleId`,
                            headers: { 
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                              },
                            data: {
                                "user_id":props.userinfo.id,
                                "rule_id":ruleres.data.data.rule_id,
                                "diamond_type":rulediamondtype
                            },
                        })
                        .then(countresponse => {
                            console.log(countresponse,"countresponse")
                            setCountLoader(false)
                            if(countresponse && countresponse.data && countresponse.data.diamondcount){
                                setTotalCount(countresponse.data.diamondcount)
                            }
                            else{
                                setTotalCount(0)
                            }
                        })
                    }
                })
            }
        })
            }
        })
    },[updateapi,update,pagenumber,sorting])
    console.log(data,"DATATATAATAT")
    const [totalcount,setTotalCount] = useState(0)

    // useEffect(() => {
    //     let finaloutput = []
    //     let promises = []
    //     let count = 0
    //     for (let i = 0; i < data.length; i++) {
            
    //         if((location.state.naturaldiamond || location.state.labdiamond) && data[i].whitetotal && data[i].on_off === 1){
    //             count += data[i].whitetotal
    //         }
    //         if((location.state.naturalfancydiamond || location.state.labfancydiamond) && data[i].fancytotal && data[i].on_off === 1){
    //             count += data[i].fancytotal
    //         } 
    //     }
    //     setTotalCount(count)
    //     setAllSupplierData(data)
    // }, [data])
    useEffect(() => {
        
    },[])
    // console.log(erpireddate,"erpireddate")
    const columns = useMemo(()=> MockShortListColumn(setUpdate,update,erpireddate,toast,props.language,setData,data,{setSupplierChanged:setSupplierChanged,supplierdata:supplierdata,setSupplierData:setSupplierData}),[erpireddate,data] )

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        gotoPage,
    } = useTable({
        columns,
        data,
        autoResetPage: false
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    // {
                    //     id:'selection',
                    //     Header:({ getToggleAllPageRowsSelectedProps }) =>(
                    //         <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                    //     ),
                    //     Cell: ({ row }) => (
                    //         <div>
                    //           <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                    //         </div>
                    //       ),
                    // },
                    // {
                    // Header: () => null,
                    // id: 'expander',
                    // Cell: ({ row }) => (
                    //     <span  {...row.getToggleRowExpandedProps()}>
                    //         {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  row.original.ShortListedStones?<FaChevronRight className="text-primary" title="Expand"/>:""}
                    //     </span>
                    // ),
                    // },
                    ...columns,
                ]
            })
        }
    )
    const [apistatus,setApiStatus] = useState()

    const FinalOk = (api_on_off) => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/fetch-Rule-ByName`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
              },
            data: {
                user_id:props.userinfo.id,
                rule_name:location.state.rule_name
            },
        }).then(ruleres => {
            if(ruleres && ruleres.data && ruleres.data.success){
                let supplierson = data.map(val => val.supplier_name)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/turn-AllOnorOff-NewSupplier-Rule`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
              },
            data: {
                user_id:props.userinfo.id,
                on_off:api_on_off,
                suppliers:supplierson,
                rule_id:ruleres.data.data.rule_id
            },
        }).then(supreq => {
            if(supreq && supreq.data){
                if(supreq.data){
                setUpdate(!update)
                setSwalOpen(false)

                if(api_on_off === 1){
                    toast.success("Your All Suppliers Turned on")
                }else{
                    toast.error("Your All Suppliers Turned Off")
                }
                }
            }
        })
            }
        })    
    }
    const SaveSuppliers = () => {
        let promises = []
        for (let i = 0; i < supplierdata.length; i++) {
          promises.push(
                axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/update-Supplier-RuleOnOff`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
              },
            data: {
              rule_id:supplierdata[i].rule_id,
              user_id:supplierdata[i].user_id,
              on_off:supplierdata[i].on_off,
              sup_id:supplierdata[i].sup_id
            },
        }).then(supreq => {
            if(supreq && supreq.data){
                return supreq.data
            }
        })
          )
        }
        Promise.all(promises).then(() => {
          toast.success("Your Feeds are Updated!")
          setSupplierChanged(false)
          setSupplierData([])
          setupdateApi(!updateapi)
        });
        // axios({
        //     method: "POST",
        //     url: `${NODE_API_URL}/IVP/updateDataFromSupReqable`,
        //     headers: { "Content-Type": "application/json" },
        //     data: {
        //         "fields": [
        //             {
        //                 "api_on_off": switchvalue
        //             }
        //         ],
        //         "condition": [
        //             {
        //                 "sr_id": d.sr_id
        //             }
        //         ]
        //     },
        // }).then(supreq => {
        //     if(supreq && supreq.data){
        //         if(supreq.data){
        //         setUpdate(d)
        //         if(switchvalue === 1){
        //             toast.success(`Your Feed Turned on for ${d.supplier_name_o}`)
        //         }else{
        //             toast.error(`Your Feed Turned off for ${d.supplier_name_o}`)
        //         }
        //         }
        //     }
        // })
      }
      const CancelSuppliers = ()=> {
        setSupplierChanged(false)
        setSupplierData([])
        setupdateApi(!updateapi)
      }
    return (
        <>{loading?<ApiDataSkeleton /> :!data.length  > 0 ? <h6 className="mt-25 text-center text-danger">Please turn On Suppliers from Supplier Management Page</h6> : 
        <div>
          <SwalWarn show={swalopen} message={apistatus === 1?"are you sure you want to turn on all?":"are you sure you want to turn off all?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => FinalOk(apistatus)}/> 
            <div className="row ">
                <div className="col-12 d-flex justify-content-between px-6 mb-5">
                    <div className="font-size-h4 font-weight-bold text-dark">
                        Supplier Management : <span className='font-weight-bold'>({location.state.rule_name})</span>
                    </div>
                    {/* <Button
                     variant="contained"
                     color="primary"
                     className={classes.button}
                     onClick={() => window.location.replace('/diamondfeed')}
                    //  disabled={!allsupplierdata.length}
                     >
                      Back to Dashboard
                    </Button> */}
                    <div className='d-flex align-items-center'>
                        <button className="btn btn-text-dark btn-hover-light-dark"  onClick={() =>window.location.replace('/diamondfeed')}><BsArrowLeftShort /> Back to dashboard</button>
                        <SupplierSortingDropdown sorting={sorting} setSorting={setSorting} /> 
                    {/* <select
                className="custom-select border border-primary rounded-0"
                onChange={(e) => setSorting(e.target.value)}
                value={sorting || ""}
            >
                <option value="" disabled selected>
                    Sort By
                </option>
                <optgroup label="Supplier Name">
                    <option selected={sorting === "supplier_desc"} value="supplier_desc">
                        Z TO A
                    </option>
                    <option selected={sorting === "supplier_asc"} value="supplier_asc">
                        A TO Z
                    </option>
                </optgroup>
                <optgroup label="Diamond Count">
                    <option selected={sorting === "count_desc"} value="count_desc">
                        High To Low
                    </option>
                    <option selected={sorting === "count_asc"} value="count_asc">
                        Low To High
                    </option>
                </optgroup>
            </select> */}
                    </div>
                </div>
              
                <div className="col-12 bg-white rounded px-6 pt-3">
                    <div className="table-responsive w-100">
                        <table className="table table-hover" {...getTableProps()}>
                            <thead className="custom_datatable_head" >
                                {headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className={`text-${column.Header === "Supplier Name"?"left":"center"} align-middle`} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row,i) => {
                                    prepareRow(row)
                                    return(
                                        <React.Fragment key={i}>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12 bg-white px-6 pb-8">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Total Count : {countloader?"Loading Count...":totalcount}</span>
                        <div>
                            {/* <button type="button" className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button> */}
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => setPageNumber(pagenumber - 1)} disabled={pagenumber === 1}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted mr-2">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pagenumber}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    disabled={true}
                                    style={{maxWidth:'70px'}}
                                />
                            </span>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => setPageNumber(pagenumber + 1)} disabled={data.length !== 10}><FaAngleRight /></button>
                            {/* <button type="button" className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button> */}
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page: 10</span>
                            {/* <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select> */}
                        </span>
                    </div>
                    
                </div>
                <div className="col-12 text-right mr-5">
                    {supplierchanged?<>
                        <Button
                      // disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0)}
                      // type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={SaveSuppliers}
                    >
                      Save
                    </Button>
                    <ErrorButton
                      // disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0)}
                      // type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={CancelSuppliers}
                    >
                      Cancel
                    </ErrorButton>
                    </>:""}
                    </div>
            </div>
        </div>
        }
        </>
    )
}