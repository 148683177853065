import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { FaInfoCircle } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import CertificateLink from "../../CertificateLink";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const MockDocTableColumn = (row,language)  => [
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS_SR,
        accessor: "",
        className:"text-center align-middle mr-3",
        Cell: (row) => {
            return <div>{(parseInt(row.row.id) + 1)}</div>;
        },
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: language.DETAIL_DIAMONDDETAILS,
        accessor: (d) => (
            <>
            {d.diamondType === "PN" || d.diamondType === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.cShape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.cShape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamondType}_diamond mr-3 small`}>
                                {d.diamondType}
                            </span>
                            <span className={`cursor-pointer small ml-2`}>
                                {d.diamondType}-{d.stockNO}
                            </span>
                            <span className={`text-capitalize small ml-2`}>
                                By: {d.type}
                            </span>
                            <span className={`text-capitalize small ml-2`}>
                                {d.length_mm?d.length_mm.toFixed(2):"" }-{d.width_mm?d.width_mm.toFixed(2):"" } MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {d.cWeight?d.cWeight.toFixed(2):""}Ct.{" "}
                            {d.pieces}Pcs.{" "}
                            {d.cColor}{" "}
                            {d.cClarity}{" "}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.awsImage &&  d.awsImage !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={d.awsImage}
                                width="50px"
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                        )
                                    }}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamondType === "N" ? d.cColor === "fancy" ? "F" : "W" : d.cColor === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                {d.diamondType === "N" || d.diamondType === "W" ? d.cColor === "fancy"  ? "NF" : "N" : d.cColor === "fancy" ? "LF" : "L"}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="mx-2 small"
                                href={
                                    d.lab === "IGI"
                                        ? `https://www.igi.org/verify-your-report/?r=LG${d.certiNO}`
                                        : d.lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${d.certiNO}`
                                        : d.lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.certiNO}`
                                        : d.lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.certiNO}`
                                        : d.Certi_link
                                }
                            >
                                {d.lab}-{d.certiNO}
                            </a>
                            {/* <CertificateLink Certificate_link={d.Certi_link} lab={d.lab} certificate={d.CertiNO} useIcon hideLabName className={`mx-2 small`} /> */}
                            <span  className={`cursor-pointer small ml-2`}>
                                {d.diamondType === "N" ? "N" : "L"}-{d.stockNO}
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {d.cWeight}{" "}
                            {d.cColor === "fancy" ? (
                                <>
                                    {d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.cColor}{" "}</>
                            )}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
   
    {
        Header:language.CONFIRM_GOODS_POPUP_DIAMOND_LOCATION,
        className:"text-center align-middle mr-3",
        accessor: "country",
    },
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS_HSCODE,
        className:"text-center align-middle mr-3",
        accessor: (d) => (
            <> 
                {d.diamondType === "N" || d.diamondType === "W" ? "71023910" : d.diamondType === "L"?"71049120":d.diamondType === "PL"?"71049190":d.cg_carat > 0.99?"71023920" : "71023910"}
            </>
        ),
    },
   
    {
        Header:language.SEARCHLIST_DISC,
        className:"text-center align-middle mr-3",
        // accessor: d => Number(d.discount),
        sortMethod: (a, b) => Number(a)-Number(b),
        // Cell: (row) => {
        //     return <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>;
        //   }
        accessor:(d) => {
            return <>{d.diamondType === "N" || d.diamondType === "W" || d.diamondType === "L"?<span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{d.discount}%</span>:"-"}</>;
        }
    },
    {
        Header:'INR/CT',
        className:"text-center align-middle mr-3",
        accessor: d => Number(d["ourRateExchange"]),
        sortMethod: (a, b) => Number(a)-Number(b),
        Cell: (row) => {
            return <span className="font-weight-bold">
                    ₹{numberWithCommas(parseFloat(row.value))}
                </span>
          }
    },
    {
        Header:language.CONFIRM_GOODS_POPUP_TOTAL_INR,
        className:"text-center align-middle mr-3",
        accessor: d => Number(d["ourPriceExchange"]),
        sortMethod: (a, b) => Number(a)-Number(b),
        Cell: (row) => {
            return <span className="font-weight-bold">
                    ₹{numberWithCommas(parseFloat(row.value))}
                </span>
          }
    },
];
