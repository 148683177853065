import React ,{useEffect} from "react";
import { Accordion, Card } from "react-bootstrap";
import {Shape} from "./Basic/Shape"
import { Carat } from "./Basic/Carat";
import {Color} from "./Basic/Color"
import { Clarity } from "./Basic/Clarity";
import { Lab } from "./Basic/Lab";
import { LabLab } from "./Basic/LabLab";
import { Shortcut } from "./Basic/Shortcut";
import { Finish } from "./Basic/Finish";
import { Flour } from "./Basic/Flour";
import { Location } from "./Basic/Location";
export const Basic = ({formik,language,option,diamond_type,oldvalues}) => {
        useEffect(() => {
            for(let key in oldvalues){
                formik.setFieldValue(key,oldvalues[key])
            }
        },[])
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        {language.BASIC_BASIC}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <Shape language={language} formik={formik}/>
                            <Carat language={language} formik={formik} oldvalues={oldvalues}/>
                            <Color language={language} formik={formik} option={option}/>
                            <Clarity language={language}/>
                            {diamond_type === "L" ? <LabLab language={language}/> : <Lab language={language}/>}
                            <Finish language={language}/>
                            <Flour language={language}/>
                            <Shortcut language={language} formik={formik} option={option}/>
                            <Location language={language} formik={formik} diamond_type={diamond_type}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
